import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

let url = process.env.REACT_APP_API_URL

const EditSupportTicket = ({ handleCloseModal, selectedForm, handleDataChange }) => {

    const adminToken = localStorage.getItem("token");

    const Navigate = useNavigate()

    const [comment, setComment] = useState("")
    const [status, setStatus] = useState("")
    const [imagePreviews, setImagePreviews] = useState(
        selectedForm?.image
    );

    useEffect(() => {
        setComment(selectedForm?.comment)
        setStatus(selectedForm?.status)
    }, [selectedForm])

    const handleSubmit = async (e) => {

        const formData = {
            comment: comment,
            status: status
        }

        try {
            let response = await axios.put(
                `${url}/ticket/update/byAdmin/${selectedForm?._id}`,
                formData,
                {
                    headers: {
                        Authorization: `${adminToken}`,
                    },
                }
            );
            if (response.data.type === "success") {
                // await handleSendNotification(selectedForm)
                alert(response.data.message);
                handleDataChange()
                handleCloseModal()
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            alert("Comment not Update !");
        }

    };

    // send notification to user
    const handleSendNotification = async (data) => {
        // send notification for comment of query
        let datas = {
            userName: data?.User_Name,
            userMobNo: data?.User_Mobile_No,
            orderId: data?.orderId || "",
            subject: data?.subject,
        }

        try {
            await axios
                .post(`${url}/notification/support/byadmin`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                        datas
                    })
        } catch (error) {

        }
    }

    const ComplainStatus = [
        { value: "0", name: 'Pending' },
        { value: "1", name: 'In Progress' },
        { value: "2", name: 'Resolved' },
        { value: "3", name: 'Closed' },
    ]


    return (
        <>
            <div className="main-content-model dark">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card model-card">
                                    <div className="card-body">
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">Support Ticket</h4>
                                            <i
                                                className="fas fa-window-close"
                                                style={{ cursor: "pointer", color: "red" }}
                                                onClick={handleCloseModal}
                                            ></i>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Ticket Id:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedForm?.ticketId}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Order Id:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedForm?.order}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                User Name:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedForm?.userName}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                User Mobile No:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    id="example-text-input"
                                                    value={selectedForm?.mobileNo}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Title:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedForm?.title}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Description:
                                            </label>
                                            <div className="col-md-10">
                                                <textarea
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedForm?.description}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Date (Time):
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedForm?.DateTime}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                                                Images:
                                            </label>
                                            <div className="col-md-10">
                                                <img
                                                    src={imagePreviews}
                                                    alt="Preview"
                                                    style={{ marginTop: "5px", marginLeft: "15px" }}
                                                    height={150}
                                                    width={150}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="example-text-input" className="col-md-2 col-form-label">
                                                Comment:
                                            </label>
                                            <div className="col-md-10">
                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Status:
                                            </label>
                                            <div className="col-md-10">
                                                <select
                                                    required
                                                    className="form-select"
                                                    id="subcategory-select"
                                                    value={status}
                                                    onChange={(e) => {
                                                        setStatus(e.target.value);
                                                    }}
                                                >
                                                    {ComplainStatus.map((value) => (
                                                        <option key={value?.name} value={value?.value}>
                                                            {value?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-10">
                                            <div className="col ms-auto">
                                                <div className="d-flex flex-reverse flex-wrap gap-2">
                                                    <a
                                                        className="btn btn-danger"
                                                        onClick={handleCloseModal}
                                                    >
                                                        {" "}
                                                        <i className="fas fa-window-close"></i>{" "}
                                                        Cancel{" "}
                                                    </a>
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        {" "}
                                                        <i className="fas fa-save"></i> Save{" "}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditSupportTicket