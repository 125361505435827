const initialTag = {
    payload: []
}


const TagDataChange = (tag = initialTag, action) => {
    switch (action.type) {
        case "EDIT_TAG":
            const { data } = action.payload
            return {
                payload: data
            }

        default:
            return tag
    }
}


export { TagDataChange }