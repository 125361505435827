export const editUser = (userId) => {
    return {
        type: "EDIT_USER",
        payload: {
            data: userId
        }
    }
}

export const showUserPackageHisory = (userId) => {
    return {
        type: "SHOW_PACKAGE_HISTORY",
        payload: {
            data: userId
        }
    }
}