import React, { useEffect, useState } from 'react';
import axios from 'axios';
let url = process.env.REACT_APP_API_URL;

const AddTimeSlot = ({ selectedResturantId, handleCloseModal, mode }) => {
    const adminToken = localStorage.getItem('token');

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('')

    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [slotFor, setSlotFor] = useState("Delivery")

    const handleAddTimeSlot = async (e) => {
        e.preventDefault();
        setLoading(true)
        setButtonDisabled(true)

        try {
            // Send the new timeSlot data to the server
            const response = await axios.post(`${url}/timeSlot/add`, {
                startTime: startTime,
                endTime: endTime,
                type: mode,
                restaurant: selectedResturantId,
                slotFor: slotFor

            }, {
                headers: {
                    Authorization: `${adminToken}`,
                },
            });

            if (response?.data?.type === 'success') {
                handleCloseModal();
            } else if (response?.data?.type === 'warning') {
                alert(response?.data?.message)
            } else {
                console.log('Error adding timeSlot:', response?.data?.message);
            }
        } catch (error) {
            console.log('Error adding timeSlot:', error);
        } finally {
            setLoading(false)
            setButtonDisabled(false)
        }
    };

    const Options = ["Delivery", "PickUp"]


    return (
        <div className="main-content-model dark">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card model-card">
                                <div className="card-body">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0">Add New Time Slot</h4>
                                        {loading && <div className="loader">Loading...</div>}
                                        <i
                                            className="fas fa-window-close"
                                            style={{ cursor: 'pointer', color: 'red' }}
                                            onClick={handleCloseModal}
                                        ></i>
                                    </div>
                                    <form onSubmit={handleAddTimeSlot}>
                                        <div className="mb-3 row">
                                            <label htmlFor="example-text-input" className="col-md-2 col-form-label mt-3">
                                                Time Slot:
                                            </label>
                                            <div className="col-md-4">
                                                Start Time
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="time"
                                                    id="example-number-input"
                                                    value={startTime}
                                                    onChange={(e) => {
                                                        setStartTime(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                End Time
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="time"
                                                    id="example-number-input"
                                                    value={endTime}
                                                    onChange={(e) => {
                                                        setEndTime(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            {mode === "res" &&
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input" className="col-md-2 col-form-label mt-3">
                                                        Slot For:
                                                        <div className="imageSize">(Delivery or PickUp)</div>
                                                    </label>
                                                    <div className="col-md-4 mt-4">
                                                        <select
                                                            required
                                                            className="form-select"
                                                            id="subcategory-select"
                                                            value={slotFor}
                                                            onChange={(e) => setSlotFor(e.target.value)}
                                                        >
                                                            {Options.map((value, index) => (
                                                                <option key={index} value={value}>
                                                                    {value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className="col-md-2">
                                            <button type="submit" className="btn btn-primary" disabled={buttonDisabled}>
                                                Add Slot
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTimeSlot;
