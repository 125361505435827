import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, GridPagination, GridToolbarExport } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";


let url = process.env.REACT_APP_API_URL

const ShowUserAllPackageHistory = () => {

    const adminToken = localStorage.getItem('token');
    const selectedPackageHistoryData = useSelector((state) => state?.ShowPakcageHistoryData?.payload)

    const [packageHistoryData, setPackageHistoryData] = useState([])

    const Navigate = useNavigate()


    useEffect(() => {
        async function getPackageHistory() {
            try {
                const res = await axios.get(`${url}/subscriptionHistory/list/get/forUser/${selectedPackageHistoryData}`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    });
                setPackageHistoryData(res?.data?.packageHistory || []);
            } catch (error) {
                console.log(error)
            }
        }
        getPackageHistory();
    }, [packageHistoryData]);



    return (
        <>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <div className="card"> */}
                                <div className="form-group mt-3">
                                    <label htmlFor="example-text-input"
                                        className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                        PackageHistory Details :
                                    </label>
                                    <div className="col-md-12 ">
                                        <table id="t01" style={{ width: "100%" }} border="1">
                                            <tr>
                                                <th>No.</th>
                                                <th>Payment Id</th>
                                                <th>Package Title</th>
                                                <th>Package Name</th>
                                                <th>Country</th>
                                                <th>PackageHistory Price</th>
                                                <th>Meals</th>
                                                <th>Bites</th>
                                                <th>Validity (In Days)</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                            </tr>

                                            {packageHistoryData && packageHistoryData?.map((ship, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{ship?.paymentId}</td>
                                                        <td>{ship?.subscriptionData?.Subscription_Title}</td>
                                                        <td>{ship?.subscriptionData?.Subscription_Name}</td>
                                                        <td>{ship?.country}</td>
                                                        <td>{`${ship?.currency === undefined ? "$" : ship?.currency} ${ship?.subscriptionData?.Subscription_Discount_Price}`}</td>
                                                        <td>{ship?.subscriptionData?.Subscription_Meals}</td>
                                                        <td>{ship?.subscriptionData?.Subscription_Bites}</td>
                                                        <td>{ship?.subscriptionData?.Validity}</td>
                                                        <td> {ship?.startDate}</td>
                                                        <td> {ship?.endDate}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ShowUserAllPackageHistory;
