import React from 'react'

const ErrorPage = () => {
  return (
    <>
      This is Error Page
    </>
  )
}

export default ErrorPage
