import React, { useState } from "react";
import defualtImage from "../../../../resources/assets/images/add-image.png";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import AlertBox from "../../../../Components/AlertComp/AlertBox";
import { useSelector } from "react-redux";

let url = process.env.REACT_APP_API_URL

const EditVoucher = () => {

    const adminToken = localStorage.getItem('token');

    const selectedVoucherData = useSelector((state) => state?.SubscriptionDataChange?.payload);


    const Navigate = useNavigate();
    const [voucherData, setVoucherData] = useState([])
    const [voucherTitle, setVoucherTitle] = useState(selectedVoucherData?.title)
    const [voucherName, setVoucherName] = useState(selectedVoucherData?.name);
    const [voucherCode, setVoucherCode] = useState(selectedVoucherData?.code);
    const [meals, setMeals] = useState(selectedVoucherData?.meals);
    const [bites, setBites] = useState(selectedVoucherData?.bites)
    const [discPrice, setDiscPrice] = useState(selectedVoucherData?.discountPrice)
    const [oriPrice, setOriPrice] = useState(selectedVoucherData?.originalPrice)
    const [validity, setValidity] = useState(selectedVoucherData?.validity)
    const [statusMessage, setStatusMessage] = useState("");
    const [voucherAddStatus, setVoucherAddStatus] = useState();

    const [validFor, setValidFor] = useState(selectedVoucherData?.validFor);
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("");
    const [currencyOptions, setCurrencyOptions] = useState([]);


    const currency = [
        "؋", "₼", "Br", "Bs.", "৳", "$", "₵", "₡", "Kč", "Fdj", "€", "ƒ", "₣", "₲",
        "₴", "₭", "L", "ден", "₺", "₾", "₼", "Q", "₹", "Rp", "﷼", "₪", "¥", "₩", "лв",
        "₸", "₮", "NT$", "₦", "C$", "₱", "﷼", "zł", "lei", "₽", "₴", "﷼", "Rs", "R", "S",
        "₡", "kr", "₩", "₨", "ரூ", "฿", "₺", "₫", "₳", "₢", "₠", "€"
    ];

    const handleCountryChange = (selectedOptions) => {
        setSelectedCountry(selectedOptions);
    };

    const handleCurrencyChange = (selectedOptions) => {
        setSelectedCurrency(selectedOptions);
    };

    useEffect(() => {
        const selectedCountryOption = {
            value: selectedVoucherData?.country,
            label: selectedVoucherData?.country,
        };
        setSelectedCountry(selectedCountryOption);

        const selectedCurrencyOption = {
            value: selectedVoucherData?.currency,
            label: selectedVoucherData?.currency,
        };
        setSelectedCurrency(selectedCurrencyOption);
    }, [])


    useEffect(() => {
        async function getData() {
            try {
                const res = await axios.get(`${url}/subscription/get/${selectedVoucherData?._id}`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    });
                setVoucherData(res?.data?.subscription || {});
            } catch (error) {
                console.log(error)
            }
        }
        getData();
    }, [selectedVoucherData])

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await axios.get(`${url}/user/get`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    });
                const options = response?.data?.user?.filter(option => option.name)?.map((option) => ({
                    value: option?._id,
                    label: option?.name + `-(${option?.mobileNo})`
                }));
                setUserList(options);
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        }
        // Fetch country data from your API
        async function fetchCountryData() {
            try {
                const response = await axios.get(`${url}/country/list/getAll`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    });
                const options = response?.data?.country?.map((option) => ({
                    value: option.name,
                    label: option.name
                }));
                setCountryOptions(options);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        }

        // Fetch Currency 
        const options = currency?.map((option) => ({
            value: option,
            label: option
        }));
        setCurrencyOptions(options);

        fetchCountryData()
        fetchUserData()
    }, [selectedCountry]);


    useEffect(() => {
        setVoucherName(voucherData?.name);
        setVoucherCode(voucherData?.code);
        setValidity(voucherData?.validity || 0);
        setMeals(voucherData?.meals || 0);
        setBites(voucherData?.bites || 0);
        setDiscPrice(voucherData?.discountPrice || 0);
        setOriPrice(voucherData?.originalPrice || 0);
        setVoucherTitle(voucherData?.title);
        setValidFor(voucherData?.validFor || "");

        if (Array.isArray(voucherData?.User)) {
            const selectedUsersIds = voucherData?.User?.map(user => ({
                value: user?._id,
                label: user?.name + `-(${user?.mobileNo})`
            }));
            setSelectedUser(selectedUsersIds);
        } else {
            setSelectedUser([]);
        }
    }, [voucherData])


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (voucherName !== "") {

            const selectedUserIds = selectedUser.map(user => user.value);

            let formData = {
                "name": voucherName,
                "title": voucherTitle,
                "discountPrice": discPrice,
                "originalPrice": oriPrice,
                "validity": validity,
                "meals": meals,
                "bites": bites,
                "type": "vou",
                "code": voucherCode,
                "validFor": validFor,
                "selectedUser": selectedUserIds,
                "country": selectedCountry?.value,
                "currency": selectedCurrency?.value
            }

            try {
                const adminToken = localStorage.getItem('token');

                let response = await axios.put(
                    `${url}/subscription/update/${selectedVoucherData?._id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    }
                );
                if (response.data.type === "success") {
                    setVoucherAddStatus(response.data.type);
                    let alertBox = document.getElementById("alert-box");
                    alertBox.classList.add("alert-wrapper");
                    setStatusMessage(response.data.message);
                    setTimeout(() => {
                        Navigate("/admin/showVoucher");
                    }, 900);
                } else {
                    setVoucherAddStatus(response.data.type);
                    let alertBox = document.getElementById("alert-box");
                    alertBox.classList.add("alert-wrapper");
                    setStatusMessage(response.data.message);
                }
            } catch (error) {
                setVoucherAddStatus("error");
                let alertBox = document.getElementById("alert-box");
                alertBox.classList.add("alert-wrapper");
                setStatusMessage("Voucher not Update !");
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setVoucherAddStatus("");
            setStatusMessage("");
            let alertBox = document?.getElementById("alert-box");
            alertBox?.classList?.remove("alert-wrapper");
        }, 1500);

        return () => clearTimeout(timer);
    }, [voucherAddStatus, statusMessage]);

    const handleUserType = (value) => {
        setValidFor(value)
        setSelectedUser([])
    }

    const handleUserChange = (selectedOptions) => {
        setSelectedUser(selectedOptions);
    };


    return (
        <>
            <div className="main-content dark">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Edit Voucher</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Voucher Title:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={voucherTitle}
                                                        onChange={(e) => {
                                                            setVoucherTitle(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Voucher Name:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={voucherName}
                                                        onChange={(e) => {
                                                            setVoucherName(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Voucher Code:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={voucherCode}
                                                        onChange={(e) => {
                                                            setVoucherCode(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Meals:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        min={1}
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        value={meals}
                                                        onChange={(e) => setMeals(e.target.value)}
                                                        id="example-number-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Bites:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        min={1}
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        value={bites}
                                                        onChange={(e) => setBites(e.target.value)}
                                                        id="example-number-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Original Price:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        min={0}
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        value={oriPrice}
                                                        onChange={(e) => setOriPrice(e.target.value)}
                                                        id="example-number-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Discount Price:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        min={0}
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        value={discPrice}
                                                        onChange={(e) => setDiscPrice(e.target.value)}
                                                        id="example-number-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Validity:
                                                    <div className="imageSize">( Note:- Validity in days )</div>
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="Number"
                                                        value={validity}
                                                        onChange={(e) => setValidity(e.target.value)}
                                                        id="example-date-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Country :
                                                </label>
                                                <div className="col-md-10">
                                                    <Select
                                                        required
                                                        value={selectedCountry}
                                                        onChange={handleCountryChange}
                                                        options={countryOptions}
                                                        placeholder="Select Country"
                                                        className="w-full md:w-20rem"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Currency :
                                                </label>
                                                <div className="col-md-10">
                                                    <Select
                                                        required
                                                        value={selectedCurrency}
                                                        onChange={handleCurrencyChange}
                                                        options={currencyOptions}
                                                        placeholder="Select Currency"
                                                        className="w-full md:w-20rem"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="validFor"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Valid For:
                                                </label>
                                                <div className="col-md-10">
                                                    <select
                                                        className="form-select"
                                                        id="validFor"
                                                        value={validFor}
                                                        onChange={(e) => handleUserType(e.target.value)}
                                                    >
                                                        <option value="0">All</option>
                                                        <option value="1">Particular User</option>
                                                    </select>
                                                    {validFor === "1" ? (
                                                        <Select
                                                            required
                                                            value={selectedUser}
                                                            onChange={handleUserChange}
                                                            options={userList}
                                                            isMulti
                                                            placeholder="Select Users"
                                                            className="w-full md:w-20rem mt-3"
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row mb-10">
                                                <div className="col ms-auto">
                                                    <div className="d-flex flex-reverse flex-wrap gap-2">
                                                        <a
                                                            className="btn btn-danger"
                                                            onClick={() => Navigate("/admin/showVoucher")}
                                                        >
                                                            {" "}
                                                            <i className="fas fa-window-close"></i> Cancel{" "}
                                                        </a>
                                                        <button className="btn btn-success" type="submit">
                                                            {" "}
                                                            <i className="fas fa-save"></i> Save{" "}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AlertBox status={voucherAddStatus} statusMessage={statusMessage} />
            </div>
        </>
    );
};

export default EditVoucher;
