import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, GridPagination, GridToolbarExport } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

// import showTimeSlot  from '../../../../Redux/Actions/BackendActions/TimeSlotActions'
import EditTimeSlot from "./EditTimeSlot";
import AddTimeSlot from "./AddTimeSlot";


let url = process.env.REACT_APP_API_URL


const ShowTimeSlot = ({ id, role }) => {


    const adminToken = localStorage.getItem('token');
    const Navigate = useNavigate()
    const selectedResturantId = useSelector((state) => state?.ShowTimeSlotDataChange?.payload)

    const dispatch = useDispatch()

    const [timeSlotData, settimeSlotData] = useState([]);
    const [timeSlotName, settimeSlotName] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    const [changesInTimeSlot, setChangesInTimeSlot] = useState(false)

    // edit timeSlot
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState()

    // add timeSlot
    const [timeSlots, setTimeSlots] = useState([]);
    const [timeSlotAddModel, setTimeSlotAddModel] = useState(false)

    useEffect(() => {
        Modal.setAppElement(document.body);
    }, []);

    const handleOpenModal = (data, timeSlotId) => {
        setSelectedTimeSlot(data?.row)
        setIsModalOpen(true);
    };

    const handleOpenAddTimeSlotModel = () => {
        setTimeSlotAddModel(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setChangesInTimeSlot(!changesInTimeSlot)
    };

    const handleCloseAddTimeSlotModel = async () => {
        setTimeSlotAddModel(false)
        setChangesInTimeSlot(!changesInTimeSlot)
    }

    useEffect(() => {
        async function getTimeSlot() {
            try {
                const res = await axios.get(`${url}/timeSlot/list/get/${role === "restaurant" ? id : selectedResturantId}`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    });
                settimeSlotData(res?.data?.timeSlot)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
            }
        }
        getTimeSlot();
    }, [changesInTimeSlot]);

    const localeText = {
        noRowsLabel: "No Data Found 😔",
    };

    const columns = [
        {
            field: "_id",
            width: 210,
            headerName: "Id",
        },
        {
            field: "startTime",
            headerName: "Start Time",
            width: 120,
            filterable: true,
            sortable: true,
            filterType: "multiselect",
        },
        {
            field: "endTime",
            headerName: "End Time",
            width: 120,
            filterable: true,
            sortable: true,
            filterType: "multiselect",
        },
        {
            field: "slotFor",
            headerName: "Slot For",
            width: 120,
            filterable: true,
            sortable: true,
            filterType: "multiselect",
        },
        // {
        //     field: "status",
        //     headerName: "Status",
        //     width: 120,
        //     renderCell: (params) => (
        //         <div className="form-check form-switch">
        //             <input
        //                 type="checkbox"
        //                 className="form-check-input"
        //                 id={`customSwitch-${params.id}`}
        //                 onChange={() => handleTimeSlotStatus(params.row, !params.value)}
        //                 checked={params.value}
        //                 onClick={(event) => event.stopPropagation()}
        //             />
        //             <label
        //                 className="form-check-label"
        //                 htmlFor={`customSwitch-${params.id}`}
        //                 style={{ color: params.value ? "green" : "grey" }}
        //             >
        //                 {params.value ? "Enable" : "Disable"}
        //             </label>
        //         </div>
        //     ),
        //     filterable: false,
        //     sortable: true,
        //     hide: false,
        // },
        {
            field: "action",
            headerName: "Action",
            width: 90,
            renderCell: (params) => (
                <Stack direction="row">
                    <IconButton
                        aria-label="delete"
                        onClick={() => handletimeSlotDelete(params.row._id)}
                    >
                        <i class="fas fa-trash-alt font-size-16 font-Icon-Del"></i>
                    </IconButton>
                    <IconButton
                        aria-label="edit"
                        onClick={() => handleOpenModal(params)}
                    >
                        <i class="fas fa-pencil-alt font-size-16 font-Icon-Up"></i>
                    </IconButton>
                </Stack>
            ),
            filterable: false,
            sortable: false,
            hide: false,
        },
    ];

    const handletimeSlotDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${url}/timeSlot/delete/${id}`,
                        {
                            headers: {
                                Authorization: `${adminToken}`,
                            },
                        })
                    .then(() => {
                        settimeSlotData(timeSlotData?.filter((d) => d?._id !== id));
                        Swal.fire("Success!", "timeSlot has been deleted!", "success");
                    })
                    .catch((err) => {
                        console.log(err);
                        Swal.fire("Error!", "timeSlot has not been deleted!", "error");
                    });
            }
        });
    };


    const handleMultipletimeSlotDelete = () => {
        let idsToDelete = selectedRows

        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${url}/timeSlot/deletes`, {
                        data: { ids: idsToDelete },
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    })
                    .then(() => {
                        settimeSlotData(timeSlotData?.filter((d) => !idsToDelete?.includes(d?._id)));
                        Swal.fire("Success!", "timeSlot has been deleted!", "success");
                    })
                    .catch((err) => {
                        console.log(err);
                        Swal.fire("Error!", "timeSlot has not been deleted!", "error");
                    });
            }
        });
    };

    const handleTimeSlotUpdate = async () => {
        const res = await axios.get(`${url}/meal/timeSlot/get/byMealId/${selectedResturantId}`,
            {
                headers: {
                    Authorization: `${adminToken}`,
                },
            });
        const updatedData = res?.data?.timeSlots || []
        settimeSlotData(updatedData)
    };

    // const handleTimeSlotStatus = async (timeSlot, newStatus) => {
    //     try {
    //         await axios.put(
    //             `${url}/meal/timeSlot/update/status/${timeSlot?._id}`,
    //             {
    //                 status: newStatus,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `${adminToken}`,
    //                 },
    //             }
    //         );

    //         const updatedTimeSlotData = timeSlotData?.map((c) =>
    //             c._id === timeSlot._id ? { ...c, status: newStatus } : c
    //         );
    //         settimeSlotData(updatedTimeSlotData);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const handleFilter = () => {
        const filteredtimeSlotList = timeSlotData?.filter((timeSlot) => {
            const formattedtimeSlotName = (timeSlot?.name || "").toUpperCase().replace(/\s/g, "");
            let istimeSlotName = true;
            if (timeSlotName) {
                istimeSlotName = formattedtimeSlotName.includes(timeSlotName.toUpperCase().replace(/\s/g, ""));
            }

            return istimeSlotName;
        });

        // Apply search query filtering
        const filteredData = filteredtimeSlotList?.filter((timeSlot) => {
            const formattedSearchQuery = searchQuery.toUpperCase().replace(/\s/g, "");
            const rowValues = Object.values(timeSlot);
            for (let i = 0; i < rowValues.length; i++) {
                const formattedRowValue = String(rowValues[i]).toUpperCase().replace(/\s/g, "");
                if (formattedRowValue.includes(formattedSearchQuery)) {
                    return true;
                }
            }
            return false;
        });

        return filteredData;
    };
    const getRowId = (row) => row._id;

    const handleCellClick = (params, event) => {
        // Prevent row selection when clicking on the switch
        if (event.target.type !== "checkbox") {
            event.stopPropagation();
        }
    };

    return (
        <>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-2 table-heading">
                                Time-Slot
                            </div>
                            <div className="d-flex flex-wrap gap-2 mt-2">
                                <button onClick={() => handleOpenAddTimeSlotModel()} className="btn btn-primary waves-effect waves-light">
                                    Add TimeSlot <i className="fas fa-arrow-right ms-2"></i>
                                </button>
                            </div>
                            <div className="searchContainer mb-3">
                                <div className="searchBarcontainer">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="searchBar"

                                    />
                                    <ClearIcon className="cancelSearch" onClick={() => setSearchQuery("")} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <div className="datagrid-container">
                                        <DataGrid
                                            style={{ textTransform: "capitalize" }}
                                            rows={handleFilter()}
                                            columns={columns}
                                            checkboxSelection
                                            disableSelectionOnClick
                                            getRowId={getRowId}
                                            filterPanelDefaultOpen
                                            filterPanelPosition="top"
                                            slots={{
                                                toolbar: (props) => (
                                                    <div>
                                                        <GridToolbar />
                                                    </div>
                                                ),
                                            }}
                                            localeText={localeText}
                                            loading={isLoading}
                                            onCellClick={handleCellClick}
                                            onRowSelectionModelChange={(e) => setSelectedRows(e)}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[10, 25, 50, 100]}
                                        />
                                        {selectedRows.length > 0 && (
                                            <div className="row-data">
                                                <div>{selectedRows.length} Time-Slot selected</div>
                                                <DeleteIcon
                                                    style={{ color: "red" }}
                                                    className="cursor-pointer"
                                                    onClick={() => handleMultipletimeSlotDelete()}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="main-content dark"
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
            >
                <EditTimeSlot
                    handleCloseModal={handleCloseModal}
                    selectedTimeSlot={selectedTimeSlot}
                    selectedResturantId={role === "restaurant" ? id : selectedResturantId}
                    mode={"res"}
                />
            </Modal>
            <Modal
                className="main-content dark"
                isOpen={timeSlotAddModel}
                onRequestClose={handleCloseAddTimeSlotModel}
            >
                <AddTimeSlot
                    handleCloseModal={handleCloseAddTimeSlotModel}
                    selectedResturantId={role === "restaurant" ? id : selectedResturantId}
                    mode={"res"}
                />
            </Modal>

        </>
    );
};

export default ShowTimeSlot;
