import React, { useEffect, useRef, useState } from 'react'
// import './ShowOrderDetails.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import IconButton from "@mui/material/IconButton";
import Modal from "react-modal";
// import EditOrderMeal from './EditOrderMeal';
// import AddOrderMeal from './AddOrderMeal';
import Swal from 'sweetalert2';
import ImageModel from "../../../../Components/ImageComp/ImageModel";
import OrderInvoicePDF from '../../../Demo/PDFGenerator';
import { saveAs } from 'file-saver';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf'
import OrderLabelInvoicePDF from '../../../Demo/LabeGenerator';

let url = process.env.REACT_APP_API_URL

const ShowOrderDetails = () => {

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const adminToken = localStorage.getItem('token');
    // this data provided by redux store
    const selectOrderId = useSelector((state) => state?.OrderDataChange?.payload)
    const Track_Id = useSelector((state) => state?.OrderDataChangeTrackiD?.payload)
    const navigate = useNavigate()
    const [orderDetails, setOrderDetails] = useState([])
    const [addressDetails, setAddressDetails] = useState([])
    const [mealDetails, setMealDetails] = useState([])
    const [custmizationDetails, setCustmizationDetails] = useState([])
    const [restaurantDetails, setRestaurantDetails] = useState([])
    const [orderType, setOrderType] = useState()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddOrderMealModelOpen, setIsAddOrderMealModelOpen] = useState(false)

    // for big image
    const [selectedImage, setSelectedImage] = useState("");
    const [isModalOpenforImage, setIsModalOpenforImage] = useState(false);

    const handleImageClick = (imageURL) => {
        setSelectedImage(imageURL);
        setIsModalOpenforImage(true);
    };


    useEffect(() => {
        async function getOrderDetails() {
            try {
                let response = await axios.get(`${url}/order/get/admin/single/${selectOrderId}?timezone=${userTimeZone}`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    })
                setOrderDetails(response?.data?.orderList)
                setAddressDetails(orderDetails.address)
                setMealDetails(orderDetails?.mealData)
                setCustmizationDetails(orderDetails?.custmizationData)
                setRestaurantDetails(orderDetails?.restaurant)

            } catch (error) {
                console.log(error)
            }
        }

        getOrderDetails()
    }, [orderDetails])


    let createOrderType
    // for create orderType
    if (orderDetails?.shippingType === "1") {
        createOrderType = ['Pending', 'Order Approved', 'Preparing', 'In Delivery', 'Delivered', 'Order Cancelled', 'Order Rejected']
    } else {
        createOrderType = ['Pending', 'Order Approved', 'Preparing', 'Ready For Pick Up', 'Delivered', 'Order Cancelled', 'Order Rejected']
    }

    // for update orderType
    const handleUpdateOrderType = async () => {
        let response = await axios.put(`${url}/order/update/type/${selectOrderId}`, {
            orderStatus: orderType,
        },
            {
                headers: {
                    Authorization: `${adminToken}`,
                },
            })
        if (response?.data?.type === 'success') {
            navigate('/admin/showOrder')
        }
        else {
            console.log(`error`)
        }
    }

    // const handleOrderMealAdd = (order) => {
    //     setSelectedOrderData(order)
    //     setIsAddOrderMealModelOpen(true);
    // }

    // const handleOrderMealUpdate = (meal) => {
    //     setSelectedOrderMealData(meal)
    //     setIsModalOpen(true);
    // }

    // const handleOrderMealDelete = async (meal) => {

    //     await setSelectedOrderMealData(meal)

    //     const orderPorductId = selectedOrderMealData?._id

    //     try {
    //         Swal.fire({
    //             title: "Are you sure?",
    //             text: "Once deleted, you will not be able to recover this!",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Yes, delete it!",
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 axios
    //                     .delete(
    //                         `${url}/order/modify/meal/delete/${selectOrderId}/${orderPorductId}`,
    //                         {
    //                             headers: {
    //                                 Authorization: `${adminToken}`,
    //                             },
    //                         }
    //                     )
    //                     .then((res) => {
    //                         if (res?.data?.type === "success") {
    //                             Swal.fire("Success!", "Item has been deleted!", "success");
    //                         } else {
    //                             Swal.fire("Error!", "Item has not been deleted!", "error");
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         console.log(err);
    //                         Swal.fire("Error!", "Item has not been deleted!", "error");
    //                     });
    //             }
    //         });

    //     } catch (error) {
    //         console.log('Error delete item:', error);
    //     }

    // }

    const handleOrderMealDelete = async (meal) => {
        try {
            const orderMealId = meal._id;

            const result = await Swal.fire({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });

            if (result.isConfirmed) {
                const response = await axios.delete(
                    `${url}/order/modify/meal/delete/${selectOrderId}/${orderMealId}`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    }
                );

                if (response?.data?.type === "success") {
                    Swal.fire("Success!", "Item has been deleted!", "success");
                } else {
                    Swal.fire("Error!", "Item has not been deleted!", "error");
                }
            }
        } catch (error) {
            console.log('Error deleting item:', error);
            Swal.fire("Error!", "Item has not been deleted!", "error");
        }
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCloseAddOrderMealModal = () => {
        setIsAddOrderMealModelOpen(false);
    };

    return (
        <>
            <div className="main-content dark">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="col-8 table-heading">
                                Edit Orders
                            </div>

                            {/* <div className="col-2 " >
                                <PDFDownloadLink document={<OrderInvoicePDF orderDetails={orderDetails} mealDetails={mealDetails} />} fileName={`${orderDetails?.User_Name}_${new Date().toISOString().split('T')[0]}_packing_slip.pdf`}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading document...' : <button><i className='fas fa-box-tissue'> </i> Download Packing Slip</button>
                                    }
                                </PDFDownloadLink>
                            </div>

                            <div className="col-2 ">
                                <PDFDownloadLink document={<OrderLabelInvoicePDF orderDetails={orderDetails} mealDetails={mealDetails} addressDetails={addressDetails} />} fileName={`${orderDetails?.User_Name}_${new Date().toISOString().split('T')[0]}_order_label_invoice.pdf`}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading document...' : <button><i className='fas fa-receipt'> </i> Download Label</button>
                                    }
                                </PDFDownloadLink>
                            </div> */}

                            <div className="col-12 mt-2">
                                <div className="card">
                                    <div className="card-body">
                                        <form >
                                            <div className='mt-2' >
                                                <label htmlFor="example-text-input"
                                                    className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                                    User Details :
                                                </label>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        User Name :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.userName} className="form-control disableColor" disabled />
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Mobile Number :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="phone" id="phone" value={orderDetails?.mobileNo} className="form-control disableColor" disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            {orderDetails?.shippingType === "1" &&
                                                <div className='mt-2' >
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                                        Delivery Details :
                                                    </label>
                                                    <div className="mb-3 row">
                                                        {/* <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">
                                                            Type:-
                                                        </label>
                                                        <div className="col-md-10 mt-1">
                                                            <input type="text" name="name" id="name" value={addressDetails?.Type} className="form-control disableColor" disabled />
                                                        </div> */}
                                                        <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">
                                                            Address Name :-
                                                        </label>
                                                        <div className="col-md-10 mt-1">
                                                            <input type="text" name="name" id="name" value={addressDetails?.name} className="form-control disableColor" disabled />
                                                        </div>
                                                        <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">
                                                            Full Address :-
                                                        </label>
                                                        <div className="col-md-10 mt-1">
                                                            <textarea type="text" name="name" id="name" value={addressDetails?.fullAddress} className="form-control disableColor" disabled />
                                                        </div>
                                                        <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">
                                                            Notes :-
                                                        </label>
                                                        <div className="col-md-10 mt-1">
                                                            <textarea type="text" name="name" id="name" value={addressDetails?.notes} className="form-control disableColor" disabled />
                                                        </div>
                                                        {/* <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">

                                                        </label>
                                                        <div className="col-md-4 mt-1">
                                                            <input type="text" name="name" id="name" value={addressDetails?.City} className="form-control disableColor" disabled />
                                                        </div>
                                                        <div className="col-md-3 mt-1">
                                                            <input type="text" name="name" id="name" value={addressDetails?.State} className="form-control disableColor" disabled />
                                                        </div>
                                                        <div className="col-md-3 mt-1">
                                                            <input type="text" name="name" id="name" value={addressDetails?.Pincode} className="form-control disableColor" disabled />
                                                        </div>
                                                        <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">
                                                            From :-
                                                        </label>
                                                        <div className="col-md-10 mt-1">
                                                            <input type="text" name="phone" id="phone" value={addressDetails?.addressFrom} className="form-control disableColor" disabled />
                                                        </div>
                                                        <label htmlFor="example-text-input"
                                                            className="col-md-2 col-form-label">
                                                            Contact Number :-
                                                        </label>
                                                        <div className="col-md-4 mt-1">
                                                            <input type="text" name="phone" id="phone" value={addressDetails?.Phone_Number} className="form-control disableColor" disabled />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            }

                                            <div className='mt-2' >
                                                <label htmlFor="example-text-input"
                                                    className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                                    Order Details :
                                                </label>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Order Id :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.orderId} className="form-control disableColor" disabled />
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Date & time :-
                                                    </label>
                                                    <div className="col-md-5 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.Date} className="form-control disableColor" disabled />
                                                    </div>
                                                    <div className="col-md-5 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.Time} className="form-control disableColor" disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mt-3">
                                                <label className="col-md-2 control-label">Meal details :-</label>
                                                <div className="col-md-12 ">
                                                    <table id="t01" style={{ width: "100%" }} border="1">
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Image</th>
                                                            <th>Meals</th>
                                                            <th>Bites</th>
                                                            <th>Quantity</th>
                                                            <th>Total Meals</th>
                                                            <th>Total Bites</th>
                                                            {/* <th>Action</th> */}
                                                        </tr>

                                                        {mealDetails && mealDetails?.map((meal, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{meal?.name}</td>
                                                                    <td>{
                                                                        <img
                                                                            src={meal?.cover}
                                                                            alt="Meal Image"
                                                                            style={{ width: '50px', height: '50px' }}
                                                                            onClick={() => handleImageClick(meal?.cover)}
                                                                        />
                                                                    }</td>
                                                                    <td>{meal?.meals}</td>
                                                                    <td >{meal?.bites}</td>
                                                                    <td >{orderDetails?.quantity}</td>
                                                                    <td>{orderDetails?.quantity * meal?.meals}</td>
                                                                    <td>{orderDetails?.quantity * meal?.bites}</td>
                                                                    {/* <td>
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => handleOrderMealDelete(meal)}
                                                                        >
                                                                            <i class="fas fa-trash-alt font-size-16 font-Icon-Del"></i>
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="update"
                                                                            onClick={() => handleOrderMealUpdate(meal)}
                                                                        >
                                                                            <i class="fas fa-pencil-alt font-size-16 font-Icon-Up" style={{ color: "#34c38f" }}></i>
                                                                        </IconButton>
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="form-group mt-3">
                                                <label className="col-md-2 control-label">Meal Custmization details :-</label>
                                                <div className="col-md-12 ">
                                                    <table id="t01" style={{ width: "100%" }} border="1">
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Segment</th>
                                                        </tr>

                                                        {custmizationDetails && custmizationDetails?.map((custom, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{custom?.title}</td>
                                                                    <td>
                                                                        <div className="form-group mt-3">
                                                                            {/* <label className="col-md-2 control-label">Meal details :-</label> */}
                                                                            <div className="col-md-12 ">
                                                                                <table id="t01" style={{ width: "100%" }} border="1">
                                                                                    <tr>
                                                                                        <th>Name</th>
                                                                                        <th>Bites</th>
                                                                                        <th>Quantity</th>
                                                                                        <th>Total Bites</th>
                                                                                    </tr>

                                                                                    {custom?.segment && custom?.segment?.map((segment, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{segment?.name}</td>
                                                                                                <td>{segment?.bites}</td>
                                                                                                <td>{orderDetails?.quantity}</td>
                                                                                                <td>{orderDetails?.quantity * segment?.bites}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="form-group mt-3">
                                                <label className="col-md-2 control-label">Total Meal & Bites :-</label>
                                                <div className="col-md-12 orderDetailsTable">
                                                    <table id="t01" style={{ width: "100%" }} border="1">
                                                        <tr>
                                                            <th>Total Meals</th>
                                                            <th>Total Bites</th>
                                                        </tr>
                                                        <tr>
                                                            <td><b>{orderDetails?.totalMeals}</b></td>
                                                            <td><b>{orderDetails?.totalBites}</b></td>
                                                        </tr>
                                                    </table>

                                                </div>
                                            </div>

                                            {/* <div className="mb-3 mt-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Add Order Items :-
                                                </label>
                                                <div className="col-md-10">
                                                    <div className="d-flex flex-reverse flex-wrap gap-2">
                                                        <a
                                                            className="btn btn-primary"
                                                            onClick={() => handleOrderMealAdd(orderDetails)}
                                                        >
                                                            {" "}
                                                            <i className="fas fa-pen-square"></i> Add{" "}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <br />

                                            <div className='mt-2' >
                                                <label htmlFor="example-text-input"
                                                    className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                                    Restaurant Details :
                                                </label>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Restaurant Name :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={restaurantDetails?.name} className="form-control disableColor" disabled />
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Restaurant Number :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={restaurantDetails?.mobileNo} className="form-control disableColor" disabled />
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Restaurant Logo :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <img
                                                            src={restaurantDetails?.logo}
                                                            alt="Meal Image"
                                                            style={{ width: '50px', height: '50px' }}
                                                            onClick={() => handleImageClick(restaurantDetails?.logo)}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='mt-2' >
                                                <label htmlFor="example-text-input"
                                                    className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                                    Shipping Details :
                                                </label>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Shipping Type :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.ShippingType} className="form-control disableColor" disabled />
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        {`${orderDetails?.ShippingType} Date`} :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.scheduleDate} className="form-control disableColor" disabled />
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        {`${orderDetails?.ShippingType} Time`} :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.scheduleTimeSlot} className="form-control disableColor" disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mt-2' >
                                                <label htmlFor="example-text-input"
                                                    className="col-md-3" style={{ color: "#5b73e8", textDecoration: "underline" }}>
                                                    Order Status :
                                                </label>
                                                <div className="mb-3 row">
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Order Status :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <select name="o_type" id="o_type" style={{ width: "30%", height: "100%" }} className="select2" onChange={(e) => setOrderType(e.target.value)} required>
                                                            {createOrderType?.map((orderType, index) => {
                                                                if (orderDetails?.orderStatus == (index + 1)) {
                                                                    return <option key={index} value={index + 1} selected>{orderType}</option>
                                                                }
                                                                else {
                                                                    return <option key={index} value={index + 1}>{orderType}</option>
                                                                }
                                                            })}
                                                        </select>
                                                    </div>
                                                    <label htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label">
                                                        Order Cancel Reason :-
                                                    </label>
                                                    <div className="col-md-10 mt-1">
                                                        <input type="text" name="name" id="name" value={orderDetails?.reason} className="form-control disableColor" disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-10">
                                                <div className="col ms-auto">
                                                    <div className="d-flex flex-reverse flex-wrap gap-2">
                                                        <a className="btn btn-danger" onClick={() => navigate('/admin/showOrder')}>
                                                            {" "}
                                                            <i className="fas fa-window-close"></i> Cancel{" "}
                                                        </a>
                                                        <a className="btn btn-success" onClick={handleUpdateOrderType}>
                                                            {" "}
                                                            <i className="fas fa-save"></i> Save{" "}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div >


            {/* <Modal
                className="main-content dark"
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
            >
                <EditOrderMeal
                    selectOrderId={selectOrderId}
                    selectedOrderMealData={selectedOrderMealData}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>

            <Modal
                className="main-content dark"
                isOpen={isAddOrderMealModelOpen}
                onRequestClose={handleCloseAddOrderMealModal}
            >
                <AddOrderMeal
                    selectOrderId={selectOrderId}
                    selectedOrderData={selectedOrderData}
                    handleCloseModal={handleCloseAddOrderMealModal}
                />
            </Modal> */}

            <Modal
                className="main-content dark"
                isOpen={isModalOpenforImage}
            >

                <ImageModel
                    isOpen={isModalOpenforImage}
                    onClose={() => setIsModalOpenforImage(false)}
                    imageURL={selectedImage}
                />
            </Modal>

        </>
    )
}

export default ShowOrderDetails
