import React, { useState } from 'react'
import Modal from "react-modal";
import ImageModel from '../../../../Components/ImageComp/ImageModel';

const ViewsReview = ({ handleCloseModal, selectedReview }) => {

    // Helper function to generate star icons based on rating
    const renderStarRating = (rating) => {
        const stars = [];
        const fullStars = Math.floor(rating);
        const halfStar = rating - fullStars >= 0.5;

        for (let i = 0; i < fullStars; i++) {
            // stars.push(<span key={i} className="star-icon" style={{ color: "gold" }}>★</span>);
            stars.push(<span key={i} className="star-icon" style={{ color: "gold" }}><i className="fas fa-star" aria-hidden="true"></i></span>);
        }

        if (halfStar) {
            // stars.push(<span key={fullStars} className="star-icon">½★</span>);
            stars.push(<span key={fullStars} className="star-icon" style={{ color: "gold" }}><i className="fas fa-star-half" aria-hidden="true"></i></span>);
        }

        return stars;
    };

    return (
        <>
            <div className="main-content-model dark">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card model-card">
                                    <div className="card-body">
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">View Rating and Review </h4>
                                            <i
                                                className="fas fa-window-close"
                                                style={{ cursor: "pointer", color: "red" }}
                                                onClick={handleCloseModal}
                                            ></i>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                User Name:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedReview?.userName}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                User Mobile No:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    id="example-text-input"
                                                    value={selectedReview?.userMobileNo}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Portion:
                                            </label>
                                            <div className="col-md-10">
                                                <div className="form-control">
                                                    {renderStarRating(selectedReview?.portion)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Taste:
                                            </label>
                                            <div className="col-md-10">
                                                <div className="form-control">
                                                    {renderStarRating(selectedReview?.taste)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Packaging:
                                            </label>
                                            <div className="col-md-10">
                                                <div className="form-control">
                                                    {renderStarRating(selectedReview?.packaging)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Delivery / Pickup:
                                            </label>
                                            <div className="col-md-10">
                                                <div className="form-control">
                                                    {renderStarRating(selectedReview?.deliveryAndpickup)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Restaurant:
                                            </label>
                                            <div className="col-md-10">
                                                <div className="form-control">
                                                    {renderStarRating(selectedReview?.restaurant)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Club Member Vote:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedReview?.memberVote}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Personal Reference Rating:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedReview?.personalRating}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Comment:
                                            </label>
                                            <div className="col-md-10">
                                                <textarea
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedReview?.comment}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Date:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedReview?.Date}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                Time:
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    id="example-text-input"
                                                    value={selectedReview?.Time}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ViewsReview