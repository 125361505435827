import React, { useState, useEffect } from "react";
import axios from "axios";
import AlertBox from "../../../../Components/AlertComp/AlertBox";
import { useNavigate } from "react-router-dom";

let url = process.env.REACT_APP_API_URL;

const AddWallet = () => {

    const adminToken = localStorage.getItem('token');
    const Navigate = useNavigate()

    const [transType, setTransType] = useState("Credit");
    const [meals, setMeals] = useState(0)
    const [bites, setBites] = useState(0)
    const [walletAddStatus, setWalletAddStatus] = useState();
    const [statusMessage, setStatusMessage] = useState("");
    const [userList, setUserList] = useState([]);
    const [desc, setDesc] = useState("")
    const [selectedUser, setSelectedUser] = useState("");

    useEffect(() => {
        async function getAllUsers() {
            try {
                const response = await axios.get(`${url}/user/get/alluser`,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    });
                if (response.data.type === "success") {
                    setUserList(response?.data?.user || []);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getAllUsers();

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (meals) {
            const formData = {
                meals: meals,
                bites: bites,
                userId: selectedUser,
                transType: transType,
                description: desc
            }

            try {
                let response = await axios.post(
                    `${url}/wallet/add/byAdmin`,
                    formData,
                    {
                        headers: {
                            Authorization: `${adminToken}`,
                        },
                    }
                );
                if (response.data.type === "success") {
                    setWalletAddStatus(response.data.type);
                    let alertBox = document.getElementById('alert-box');
                    alertBox.classList.add('alert-wrapper');
                    setStatusMessage(response.data.message);
                    setTimeout(() => {
                        Navigate('/admin/showWalletHistory');
                    }, 900);
                } else {
                    setWalletAddStatus(response.data.type);
                    let alertBox = document.getElementById('alert-box');
                    alertBox.classList.add('alert-wrapper');
                    setStatusMessage(response.data.message);
                }
            } catch (error) {
                setWalletAddStatus("error");
                let alertBox = document.getElementById('alert-box');
                alertBox.classList.add('alert-wrapper');
                setStatusMessage("Wallet not added!");
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setWalletAddStatus("");
            setStatusMessage("");
            let alertBox = document?.getElementById('alert-box');
            alertBox?.classList?.remove('alert-wrapper');
        }, 1500);

        return () => clearTimeout(timer);
    }, [walletAddStatus, statusMessage]);

    return (
        <>
            <div className="main-content dark">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Add Wallet</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="walletCode"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Type:
                                                </label>
                                                <div className="col-md-10">
                                                    <select
                                                        required
                                                        className="form-select"
                                                        id="userType"
                                                        value={transType}
                                                        onChange={(e) => setTransType(e.target.value)}
                                                    >
                                                        <option value="Credit">Credit</option>
                                                        <option value="Debit">Debit</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="amount"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Meals:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        min={0}
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        id="amount"
                                                        value={meals}
                                                        onChange={(e) => setMeals(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="amount"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Bites:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        min={0}
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        id="amount"
                                                        value={bites}
                                                        onChange={(e) => setBites(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="amount"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Description:
                                                </label>
                                                <div className="col-md-10">
                                                    <textarea
                                                        min={1}
                                                        className="form-control"
                                                        type="text"
                                                        value={desc}
                                                        onChange={(e) => setDesc(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="userType"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User:
                                                </label>
                                                <div className="col-md-10">
                                                    <select
                                                        required
                                                        className="form-select"
                                                        value={selectedUser}
                                                        onChange={(e) => setSelectedUser(e.target.value)}
                                                    >
                                                        <option value="">Select User</option>
                                                        {userList.map((user) => (
                                                            <option key={user?._id} value={user?._id}>
                                                                {user?.name}-({user?.mobileNo})
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-10">
                                                <div className="col ms-auto">
                                                    <div className="d-flex flex-reverse flex-wrap gap-2">
                                                        <a className="btn btn-danger" onClick={() => Navigate('/admin/showWalletHistory')}>
                                                            {" "}
                                                            <i className="fas fa-window-close"></i> Cancel{" "}
                                                        </a>
                                                        <button className="btn btn-success" type="submit">
                                                            {" "}
                                                            <i className="fas fa-save"></i> Save{" "}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AlertBox status={walletAddStatus} statusMessage={statusMessage} />
            </div>
        </>
    );
};

export default AddWallet;
