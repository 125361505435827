import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AlertBox from "../../../../Components/AlertComp/AlertBox";
import userImage from '../../../../resources/assets/images/3135715.png'

const adminToken = localStorage.getItem('token');

let url = process.env.REACT_APP_API_URL

const EditUser = () => {

    const Navigate = useNavigate()
    const selectedUserData = useSelector((state) => state?.UserDataChange?.payload)

    const [userName, setUserName] = useState(selectedUserData?.name);
    const [mobileNumber, setMobileNumber] = useState(`${selectedUserData?.mobileCode} ${selectedUserData?.mobileNo}`)
    const [email, setEmail] = useState(selectedUserData?.email)
    const [meal, setMeal] = useState(selectedUserData?.meals)
    const [bite, setBite] = useState(selectedUserData?.bites)
    const [role, setRole] = useState(selectedUserData?.role)
    const [goal, setGoal] = useState(selectedUserData?.goal)
    const [validity, setValidity] = useState(selectedUserData?.validity)
    const [previewImage, setPreviewImage] = useState(selectedUserData?.image)
    const [userAddStatus, setUserAddStatus] = useState(selectedUserData?.User_Status);
    const [statusMessage, setStatusMessage] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            meals: meal,
            bites: bite,
        }

        try {
            let response = await axios.put(
                `${url}/user/update/byAdmin/${selectedUserData?._id}`,
                formData,
                {
                    headers: {
                        Authorization: `${adminToken}`,
                    },
                }
            );
            if (response.data.type === "success") {
                setUserAddStatus(response.data.type);
                let alertBox = document.getElementById('alert-box')
                alertBox.classList.add('alert-wrapper')
                setStatusMessage(response.data.message);
                setTimeout(() => {
                    Navigate('/admin/showUser');
                }, 900);
            } else {
                setUserAddStatus(response.data.type);
                let alertBox = document.getElementById('alert-box')
                alertBox.classList.add('alert-wrapper')
                setStatusMessage(response.data.message);
            }
        } catch (error) {
            setUserAddStatus("error");
            let alertBox = document.getElementById('alert-box')
            alertBox.classList.add('alert-wrapper')
            setStatusMessage("User not Update !");
        }

    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setUserAddStatus("");
            setStatusMessage("");
            let alertBox = document?.getElementById('alert-box')
            alertBox?.classList?.remove('alert-wrapper')
        }, 1500);

        return () => clearTimeout(timer);
    }, [userAddStatus, statusMessage]);


    return (
        <>
            <div className="main-content dark">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <div className="col-2 table-heading">
                                        Edit List
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Name:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        readOnly
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={userName}
                                                        onChange={(e) => {
                                                            setUserName(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Mobile No:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        readOnly
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={mobileNumber}
                                                        onChange={(e) => {
                                                            setMobileNumber(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Email:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        readOnly
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Role:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        readOnly
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={role}
                                                        onChange={(e) => {
                                                            setRole(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Goal:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        readOnly
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={goal}
                                                        onChange={(e) => {
                                                            setGoal(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Meals:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        id="example-number-input"
                                                        value={meal}
                                                        onChange={(e) => {
                                                            setMeal(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Bites:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        required
                                                        className="form-control"
                                                        type="number"
                                                        id="example-number-input"
                                                        value={bite}
                                                        onChange={(e) => {
                                                            setBite(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Package Validity:
                                                </label>
                                                <div className="col-md-10">
                                                    <input
                                                        readOnly
                                                        required
                                                        className="form-control"
                                                        type="text"
                                                        id="example-text-input"
                                                        value={validity}
                                                        onChange={(e) => {
                                                            setValidity(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    User Image:
                                                </label>
                                                <div className="col-md-10">
                                                    <div className="fileupload_img col-md-10 mt-2">
                                                        <img
                                                            type="image"
                                                            src={`${previewImage}`}
                                                            alt="user image"
                                                            height={100}
                                                            width={100}
                                                            onError={(e) => {
                                                                e.target.src = userImage
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-10">
                                                <div className="col ms-auto">
                                                    <div className="d-flex flex-reverse flex-wrap gap-2">
                                                        <a className="btn btn-danger" onClick={() => Navigate('/admin/showUser')}>
                                                            {" "}
                                                            <i className="fas fa-window-close"></i> Cancel{" "}
                                                        </a>
                                                        <button className="btn btn-success" type="submit">
                                                            {" "}
                                                            <i className="fas fa-save"></i> Save{" "}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AlertBox status={userAddStatus} statusMessage={statusMessage} />
            </div>
        </>
    );
};

export default EditUser;
